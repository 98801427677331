<template>
  <div>
    <v-simple-table>
      <tbody>
        <tr v-if="$platform">
          <td class="text-h7 text-right">Platform:</td>
          <td class="monospace">{{ $platform }}</td>
        </tr>
        <tr v-if="appBuild">
          <td class="text-h7 text-right">Build:</td>
          <td class="monospace">{{ appBuild }}</td>
        </tr>
        <tr v-if="appVersion">
          <td class="text-h7 text-right">Version:</td>
          <td class="monospace">{{ appVersion }}</td>
        </tr>
        <tr>
          <td class="text-h7 text-right">API Server:</td>
          <td class="monospace">{{ apiEndpoint }}</td>
        </tr>
        <tr>
          <td class="text-h7 text-right">API Version:</td>
          <td class="monospace">{{ apiVersion }}</td>
        </tr>
        <tr>
          <td class="text-h7 text-right">API Server Time:</td>
          <td class="monospace">{{ apiTime }} (UTC)</td>
        </tr>
        <tr>
          <td class="text-h7 text-right">API Response Time:</td>
          <td class="monospace">{{ apiResponseTime }}</td>
        </tr>
        <tr v-if="fcmToken && $store.state.api.apiURL.includes('dev')">
          <td class="text-h7 text-right">FCM Token:</td>
          <td class="monospace">{{ fcmToken }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
<script>
import store from "@/store";
import { InternalService } from "@services";
import { App } from "@capacitor/app";
import { mapGetters } from "vuex";

export default {
  props: {
    appName: {
      type: String,
      default: null,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("notifications", ["fcmToken"]),
  },
  data() {
    return {
      appBuild: "",
      appPlatform: "",
      appVersion: "",
      apiEndpoint: "",
      apiVersion: "",
      apiTime: null,
      apiResponseTime: null,
      apiTimeTimout: null,
      doApiTimeTimeout: false,
    };
  },
  methods: {
    getApiVersion() {
      InternalService.versionGet()
        .then((result) => {
          this.apiVersion = result.data?.version;
        })
        .catch((err) => {
          if (err?.message) {
            this.apiVersion = err?.message;
          } else {
            this.apiVersion = "An unknown error occured!";
          }
        });
    },
    getApiTime() {
      const t0 = performance.now();
      InternalService.serverTimeGet()
        .then((result) => {
          const t1 = performance.now();
          const responseTime = Math.round((t1 - t0) * 10) / 10;
          this.apiTime = result.data?.utc;
          this.apiResponseTime = `${responseTime.toFixed(1)} milliseconds`;

          if (this.doApiTimeTimeout) {
            this.apiTimeTimeout = setTimeout(() => {
              clearTimeout(this.apiTimeTimeout);
              this.getApiTime();
            }, 1000);
          }
        })
        .catch((err) => {
          if (err?.message) {
            this.apiTime = err?.message;
          } else {
            this.apiTime = "An unknown error occured!";
          }
        });
    },
    stopApiTimeTimeout() {
      this.doApiTimeTimeout = false;
      clearTimeout(this.apiTimeTimeout);
    },
    getAppInfo() {
      if (this.$platform !== "web") {
        App.getInfo().then((a) => {
          this.appBuild += a.build;
          this.appVersion += a.version;
        });
      } else {
        this.appVersion = store.state.settings.version;
      }
    },
  },
  mounted() {
    this.doApiTimeTimeout = this.refresh;
    this.apiEndpoint = store.state.api.apiURL.replace("https://", "").slice(0, -1);
    this.getApiVersion();
    this.getApiTime();
    this.getAppInfo();
  },
  beforeRouteUpdate(to, from, next) {
    this.stopApiTimeTimeout();
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.stopApiTimeTimeout();
    next();
  },
  beforeUnmount() {
    this.stopApiTimeTimeout();
  },
  beforeDestroy() {
    this.stopApiTimeTimeout();
  },
};
</script>
<style scoped>
.monospace {
  overflow-wrap: break-word;
}
table td + td {
  border-left: 1px solid #333;
}
</style>
