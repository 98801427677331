<template>
  <div class="signin vh-100">
    <v-container class="fill-height">
      <v-row justify="center" align="center">
        <v-container>
          <v-row>
            <v-col sm="10" md="8" offset-md="2" offset-sm="1">
              <v-card elevation="4">
                <app-public-header />
                <v-divider />
                <v-card-text>
                  <version :refresh="true" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Helpers from "@/mixins/helpers";
import AppPublicHeader from "@/components/AppPublicHeader";
import Version from "@/components/Version";

export default {
  name: "Status",
  metaInfo: {
    title: "Status",
  },
  components: {
    AppPublicHeader,
    Version,
  },
  mixins: [Helpers],
};
</script>
